<template>
    <div>
        <div v-if="show" class="p-grid">
            test face
            <ul>
                <li v-for="item in items" :key="item.message">
                    {{ item.AgeRange }} (gender: {{ item.Gender }}, smile: {{ item.Smile }})
                </li>
            </ul>

            <div>
                <Button
                    label="Draw"
                    @click="draw"
                />
                <div class="canvas-wrapper">
                    <!-- <img src="https://picsum.photos/600/300/?image=25" style="width:400px" fluid-grow> -->
                    <img :src="image" class="img-overlay" fluid-grow>
                    <canvas ref="myCanvas" class="canvas-overlay" />
                </div>
                <div>
                    <img :src="image" class="p-col-12">
                    {{ image }}
                </div>
            </div>
        </div>
        <input id="fileToUpload" type="file" name="fileToUpload" accept="image/*" @change="ProcessImage">
        <div v-if="$store.state.debug.log">
            <br>
            <!-- ITEMS ************************** -->
            <!-- <vue-json-pretty :data="items" /> -->
        </div>
    </div>
</template>

<script>
const AWS = require('aws-sdk')

export default {
        data() {
                return {
                        items: [],
                        image: null
                }
        },
        computed: {
                show() {
                        return true;
                },
        },
        created() {
        },
        mounted() {
        },
        methods: {
                draw() {
                        let c = this.$refs['myCanvas']
                        const canvas = c.getContext('2d')
                        canvas.beginPath()
                        // canvas.lineWidth = "10";
                        // canvas.strokeStyle = "red";
                        // canvas.rect(20, 20, 150, 100)

                        let widthRatio = 400
                        let heightRatio = 267

                        for (let item of this.items) {
                                console.log(item)
                                let tmp = item.BoundingBox
                                canvas.rect(tmp.Left * widthRatio, tmp.Top * heightRatio, tmp.Width * widthRatio, tmp.Height * heightRatio)
                        }

                        canvas.stroke()
                },
                //Calls DetectFaces API and shows estimated ages of detected faces
                DetectFaces(imageData) {
                        AWS.region = "eu-central-1";
                        var rekognition = new AWS.Rekognition();
                        var params = {
                                Image: {
                                        Bytes: imageData
                                },
                                Attributes: [
                                        'ALL',
                                ]
                        };
                        let self = this
                        rekognition.detectFaces(params, function (err, data) {
                                if (err) console.log(err, err.stack); // an error occurred
                                else {
                                        console.log(data)
                                        self.items.splice(0, self.items.length)
                                        self.items.push(...data.FaceDetails)
                                        // var table = "<table><tr><th>Low</th><th>High</th></tr>";
                                        // // show each face and build out estimated age table
                                        // for (var i = 0; i < data.FaceDetails.length; i++) {
                                        //         table += '<tr><td>' + data.FaceDetails[i].AgeRange.Low +
                                        //                 '</td><td>' + data.FaceDetails[i].AgeRange.High + '</td></tr>';
                                        // }
                                        // table += "</table>";
                                        // document.getElementById("opResult").innerHTML = table;
                                }
                        });
                },
                //Loads selected image and unencodes image bytes for Rekognition DetectFaces API
                ProcessImage() {
                        console.log('process image')
                        this.AnonLog();
                        var control = document.getElementById("fileToUpload");
                        var file = control.files[0];

                        // Load base64 encoded image
                        var reader = new FileReader();
                        let self = this
                        reader.onload = (function (theFile) {
                                return function (e) {
                                        var img = document.createElement('img');
                                        var image = null;
                                        img.src = e.target.result;
                                        self.image = img.src
                                        var jpg = true;
                                        try {
                                                image = atob(e.target.result.split("data:image/jpeg;base64,")[1]);

                                        } catch (e) {
                                                jpg = false;
                                        }
                                        if (jpg == false) {
                                                try {
                                                        image = atob(e.target.result.split("data:image/png;base64,")[1]);
                                                } catch (e) {
                                                        alert("Not an image file Rekognition can process");
                                                        return;
                                                }
                                        }
                                        //unencode image bytes for Rekognition DetectFaces API
                                        var length = image.length;
                                        let imageBytes = new ArrayBuffer(length);
                                        var ua = new Uint8Array(imageBytes);
                                        for (var i = 0; i < length; i++) {
                                                ua[i] = image.charCodeAt(i);
                                        }
                                        //Call Rekognition
                                        self.DetectFaces(imageBytes);
                                };
                        })(file);
                        reader.readAsDataURL(file);
                },
                //Provides anonymous log on to AWS services
                AnonLog() {
                        // Configure the credentials provider to use your identity pool
                        AWS.config.region = 'eu-central-1'; // Region
                        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                IdentityPoolId: 'eu-central-1:aedab169-3918-4846-9a52-b19a6aceca3b',
                        });
                        // Make the call to obtain credentials
                        AWS.config.credentials.get(function () {
                                // Credentials will be available when this function is called.
                                var accessKeyId = AWS.config.credentials.accessKeyId;
                                var secretAccessKey = AWS.config.credentials.secretAccessKey;
                                var sessionToken = AWS.config.credentials.sessionToken;
                        });
                }
        },
};
</script>

<style scoped  lang="scss">
.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 267px;
}

.canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 400px;
  height: 267px;
  z-index: 10;
}

.canvas-wrapper {
  position: relative;
  z-index: 1;
}</style>
